/* eslint-disable max-lines */

import {
  AUTHORITY_FEE_MERGE_FIELDS,
  AUTHORITY_MERGE_FIELDS,
  OPPORTUNITY_PROPERTY_MAIL_MERGEFIELDS,
  OWNERSHIP_MAIL_MERGEFIELDS,
  OWNERS_INSTRUCTIONS_MERGE_FIELDS,
  PRIMARY_OWNER_MERGE_FIELDS,
} from '../helper/PropertyMailMergeFieldMapper';

/* eslint-disable import/prefer-default-export */
export const MAIL_MERGE_FIELDS = [
  // TODO use same format for values
  { label: 'Insert merge field', value: '' },
  {
    label: 'Lessors',
    subFields: [
      { label: 'Name', value: 'Lessors:Name', placeHolder: 'The Lessor(s)' },
      {
        label: 'Email',
        value: 'Lessors:Email',
        placeHolder: 'The Lessor(s) Email(s)',
      },
      {
        label: 'Phone Number',
        value: 'Lessors:PhoneNumber',
        placeHolder: 'The Lessor(s) Phone Number(s)',
      },
      {
        label: 'Full Address',
        value: 'Lessors:FullAddress',
        placeHolder: 'The Lessor(s) Full Address(es)',
      },
      {
        label: 'State and Country',
        value: 'Lessors:StateAndCountry',
        placeHolder: 'The Lessor(s) State and Country',
      },
      {
        label: 'ABN',
        value: 'Lessors:ABN',
        placeHolder: 'Company ABN',
      },
      {
        label: 'ACN',
        value: 'Lessors:ACN',
        placeHolder: 'Company ACN',
      },
    ],
  },
  {
    label: 'Agent',
    subFields: [
      { label: 'Name', value: 'Agent:Name', placeHolder: 'The agent' },
      { label: 'ID', value: 'Agent:ID', placeHolder: 'The agents Id' },
      { label: 'Email', value: 'Agent:Email', placeHolder: 'The agents Email' },
      { label: 'Phone', value: 'Agent:Phone', placeHolder: 'The agents phone' },

      // TODO need to confirm with Pita/Huy
      // { label: "Branch number", value: "Agent:Branch number", placeHolder: "The agent" },
      // { label: "Branch address", value: "Agent:Branch address", placeHolder: "The agent" },
      // { label: "Branch email", value: "Agent:Branch email", placeHolder: "The agent" },
      // { label: "Business Name", value: "Agent:businessName", placeHolder: "Company name" },
      // { label: "Trading Name", value: "Agent:tradingName", placeHolder: "Trading name" },
      // { label: "Branch License", value: "Agent:license", placeHolder: "Agent license number" },
      // { label: "Branch ACN", value: "Agent:acn", placeHolder: "Company ACN" },
      // { label: "Branch ABN", value: "Agent:abn", placeHolder: "Company ABN" },
    ],
  },
  {
    label: 'Agency',
    subFields: [
      {
        label: 'Business Name',
        value: 'Agency:businessName',
        placeHolder: 'Company name',
      },
      {
        label: 'Trading Name',
        value: 'Agency:tradingName',
        placeHolder: 'Trading name',
      },
      {
        label: 'Agency License',
        value: 'Agency:license',
        placeHolder: 'Agency license number',
      },
      { label: 'Agency ACN', value: 'Agency:acn', placeHolder: 'Agency ACN' },
      { label: 'Agency ABN', value: 'Agency:abn', placeHolder: 'Agency ABN' },
    ],
  },
  {
    label: 'Branch',
    subFields: [
      {
        label: 'Business Name',
        value: 'Branch:businessName',
        placeHolder: 'Company name',
      },
      {
        label: 'Trading Name',
        value: 'Branch:tradingName',
        placeHolder: 'Trading name',
      },
      {
        label: 'Branch License',
        value: 'Branch:license',
        placeHolder: 'Branch license number',
      },
      { label: 'Branch ACN', value: 'Branch:acn', placeHolder: 'Branch ACN' },
      { label: 'Branch ABN', value: 'Branch:abn', placeHolder: 'Branch ABN' },
      {
        label: 'Branch Address',
        value: 'Branch:address',
        placeHolder: 'Branch Address',
      },
      {
        label: 'Branch Phone Number',
        value: 'Branch:phone',
        placeHolder: 'Branch Phone Number',
      },
      {
        label: 'Branch Email',
        value: 'Branch:email',
        placeHolder: 'Branch Email',
      },
    ],
  },
  {
    label: 'Property',
    // TODO update the placeholders
    subFields: [
      {
        label: 'Address',
        value: 'Property:Address',
        placeHolder: 'The property',
      },
      {
        label: 'Custom Field',
        value: 'Property:customAddressField',
        placeHolder: 'Custom field data',
      },
      {
        label: 'Bedrooms',
        value: 'Property:Bedrooms',
        placeHolder: 'No of bedrooms',
      },
      {
        label: 'Bathrooms',
        value: 'Property:Bathrooms',
        placeHolder: 'No of bathrooms',
      },
      {
        label: 'Parking',
        value: 'Property:Parking',
        placeHolder: 'No of parking',
      },
      {
        label: 'Leasing Agent Name',
        value: 'Property:leasingAgentName',
        placeHolder: 'Leasing agent',
      },
      {
        label: 'Leasing Agent Phone',
        value: 'Property:leasingAgentPhone',
        placeHolder: 'Leasing agents phone',
      },
      {
        label: 'Leasing Agent Email',
        value: 'Property:leasingAgentEmail',
        placeHolder: 'Leasing agents email',
      },
      {
        label: 'Water usage charge',
        value: 'Property:waterCharges',
        placeHolder: 'N/A',
      },
    ],
  },
  {
    label: 'Tenancy',
    subFields: [
      {
        label: 'Unit Number',
        value: 'Tenancy:unit',
        placeHolder: 'The tenancy unit number',
      },
      {
        label: 'Start date',
        value: 'Tenancy:start_date',
        placeHolder: 'The tenancy start date',
      },
      {
        label: 'Start date day',
        value: 'Tenancy:startDateDay',
        placeHolder: 'The tenancy start date day',
      },
      {
        label: 'End date',
        value: 'Tenancy:end_date',
        placeHolder: 'The tenancy end date',
      },
      {
        label: 'Rental frequency',
        value: 'Tenancy:rental_frequency',
        placeHolder: 'The rental frequency',
      },
      {
        label: 'Rental frequency Simple',
        value: 'Tenancy:rentalFrequencySimple',
        placeHolder: 'The rental frequency simple',
      },
      {
        label: 'Duration (Months only)',
        value: 'Tenancy:lease_duration_month_only',
        placeHolder: 'The tenancy duration (Month only)',
      },
      {
        label: 'Duration (Weeks only)',
        value: 'Tenancy:lease_duration_week_only',
        placeHolder: 'The tenancy duration (Week only)',
      },
      {
        label: 'Duration',
        value: 'Tenancy:lease_duration',
        placeHolder: 'The tenancy duration',
      },
      {
        label: 'Payment method',
        value: 'Tenancy:payment_method',
        placeHolder: 'The selected payment method',
      },
      {
        label: 'Payment method type',
        value: 'Tenancy:paymentMethodType',
        placeHolder: 'The selected payment method',
      },
      {
        label: 'Property provided',
        value: 'Tenancy:property_provided',
        placeHolder: ' Furnished/Unfurnished',
      },
      {
        label: 'Number of adults',
        value: 'Tenancy:number_of_adults',
        placeHolder: 'The number of permitted adults',
      },
      {
        label: 'Number of children',
        value: 'Tenancy:number_of_children',
        placeHolder: 'The number of permitted children',
      },
      {
        label: 'Pets allowed',
        value: 'Tenancy:pets_allowed',
        placeHolder: 'Allowed/Not allowed',
      },
      {
        label: 'Pets description',
        value: 'Tenancy:pets_description',
        placeHolder: 'Description of pets',
      },
      {
        label: 'Rental amount (weekly)',
        value: 'Tenancy:rental_amount',
        placeHolder: 'The rental amount',
      },
      {
        label: 'Deposit Amount',
        value: 'Tenancy:depositAmount',
        placeHolder: 'Rental first payment',
      },
      {
        label: 'Maximum urgent repair amount',
        value: 'Tenancy:max_repair_amount',
        placeHolder: 'Maximum urgent repair amount',
      },
      {
        label: 'First Payment Date',
        value: 'Tenancy:firstPaymentDate',
        placeHolder: 'First payment Date',
      },
      {
        label: 'Rent Schedule StartDate',
        value: 'Tenancy:rentScheduledStartDate',
        placeHolder: 'Rental schedule start date',
      },
      {
        label: 'One Cycle Rental Amount',
        value: 'Tenancy:oneCycleRentalAmount',
        placeHolder: 'One Cycle Rental Amount',
      },
      {
        label: 'State Bond Office',
        value: 'Tenancy:stateBondOffice',
        placeHolder: 'States bond office',
      },
      {
        label: 'Current Date',
        value: 'Tenancy:currentDate',
        placeHolder: 'Today�s date',
      },
      {
        label: 'Tenants',
        value: 'Tenancy:tenants',
        placeHolder: 'The tenant(s)',
      },
      {
        label: 'All tenant details',
        value: 'Tenancy:allTenantDetails',
        placeHolder: 'All tenant details',
      },
      {
        label: 'All tenant emails',
        value: 'Tenancy:allTenantEmails',
        placeHolder: 'All tenant emails',
      },
      {
        label: 'All renter emails',
        value: 'Tenancy:allRenterEmailsElectronicConsent',
        placeHolder: 'All renter emails',
      },
      {
        label: 'Primary Tenant Salutation',
        value: 'Tenancy:primaryTenantSalutation',
        placeHolder: 'Salutation',
      },
      {
        label: 'Primary Tenant',
        value: 'Tenancy:primaryTenant',
        placeHolder: 'Primary tenant',
      },
      {
        label: 'Primary Tenant Email',
        value: 'Tenancy:primaryTenantEmail',
        placeHolder: 'Primary tenant email',
      },
      {
        label: 'Primary Tenant Phone Number',
        value: 'Tenancy:primaryTenantPhoneNumber',
        placeHolder: 'Primary tenant phone number',
      },
      {
        label: 'Secondary Tenants',
        value: 'Tenancy:secondaryTenants',
        placeHolder: '',
      },
      {
        label: 'Secondary Tenants Email',
        value: 'Tenancy:secondaryTenantsEmail',
        placeHolder: '',
      },
      {
        label: 'Secondary Tenants Phone Number',
        value: 'Tenancy:secondaryTenantsPhoneNumber',
        placeHolder: '',
      },
      {
        label: 'Tenants with addresses',
        value: 'Tenancy:all_tenant_name_address',
        placeHolder: 'Tenant addresses',
      },
      {
        label: 'One off payment',
        value: 'Tenancy:oneOffPaymentAmount',
        placeHolder: 'One-off payment amount',
      },
      {
        label: 'Bond Amount',
        value: 'Tenancy:bondAmount',
        placeHolder: 'Bond amount',
      },
      {
        label: 'Deposit Payment Date',
        value: 'Tenancy:depositPaymentDate',
        placeHolder: 'The deposit payment date',
      },
      {
        label: 'Property viewed on',
        value: 'Tenancy:inspectionDate',
        placeHolder: 'Property viewed on',
      },
      {
        label: 'Payment provider',
        value: 'Tenancy:paymentProvider',
        placeHolder: 'Payment Provider',
      },
      {
        label: 'Biller Info',
        value: 'Tenancy:billerInfo',
        placeHolder: 'Biller Information',
      },
      {
        label: 'Rent First Due Date',
        value: 'Tenancy:paymentDateForRentFirst',
        placeHolder: 'Rent First Due Date',
      },
      {
        label: 'Rent Bond Due Date',
        value: 'Tenancy:paymentDateForRentBond',
        placeHolder: 'Rent Bond Due Date',
      },
      {
        label: 'Rent Deposit Due Date',
        value: 'Tenancy:paymentDateForRentDeposit',
        placeHolder: 'Rent Deposit Due Date',
      },
      {
        label: 'Payment Reference',
        value: 'Tenancy:payment_reference',
        placeHolder: 'Payment Reference',
      },
      {
        label: 'Bond Reference Number',
        value: 'Tenancy:bondReferenceNumber',
        placeHolder: 'Biller Information',
      },
      {
        label: 'First Weekly Rent Adjustment Amount',
        value: 'Tenancy:firstRentalIncreaseAmountWeekly',
        placeHolder: 'N/A',
      },
      {
        label: 'First One Cycle Rent Adjustment Amount',
        value: 'Tenancy:firstRentalIncreaseAmountOneCycle',
        placeHolder: 'N/A',
      },
      {
        label: 'First Rent Adjustment Date',
        value: 'Tenancy:firstRentalIncreaseDate',
        placeHolder: 'N/A',
      },
      {
        label: 'Second Weekly Rent Adjustment Amount',
        value: 'Tenancy:secondRentalIncreaseAmountWeekly',
        placeHolder: 'N/A',
      },
      {
        label: 'Second One Cycle Rent Adjustment Amount',
        value: 'Tenancy:secondRentalIncreaseAmountOneCycle',
        placeHolder: 'N/A',
      },
      {
        label: 'Second Rent Adjustment Date',
        value: 'Tenancy:secondRentalIncreaseDate',
        placeHolder: 'N/A',
      },
      {
        label: 'Tenants Included In Bond',
        value: 'Tenancy:tenantsIncludeOnBond',
        placeHolder: 'Tenants included on bond',
      },
      {
        label: 'Inclusions',
        value: 'Tenancy:inclusions',
        placeHolder: 'N/A',
      },
      {
        label: 'Condition report date',
        value: 'Tenancy:conditionReportDate',
        placeHolder: 'N/A',
      },
      {
        label: 'Bond Payment Date',
        value: 'Tenancy:bond_date',
        placeholder: 'N/A',
      },
      {
        label: 'Rent payment day',
        value: 'Tenancy:rentPaymentDay',
        placeHolder: 'N/A',
      },
      {
        label: 'Rent Increase Limit Type',
        value: 'Tenancy:rentIncreaseLimitType',
        placeHolder: 'N/A',
      },
      {
        label: 'Account name',
        value: 'Tenancy:ongoingPaymentAccountName',
        placeHolder: 'N/A',
      },
      {
        label: 'Recipient Name',
        value: 'Tenancy:recipientName',
        placeHolder: 'N/A',
      },
      {
        label: 'Recipient Address',
        value: 'Tenancy:recipientAddress',
        placeHolder: 'N/A',
      },
      {
        label: 'Bank Name',
        value: 'Tenancy:bankName',
        placeHolder: 'N/A',
      },
      {
        label: 'Bank Address',
        value: 'Tenancy:bankAddress',
        placeHolder: 'N/A',
      },
      {
        label: 'Swift Bic',
        value: 'Tenancy:swiftBic',
        placeHolder: 'N/A',
      },
      {
        label: 'Account number',
        value: 'Tenancy:ongoingPaymentAccountNumber',
        placeHolder: 'N/A',
      },
      {
        label: 'BSB',
        value: 'Tenancy:ongoingPaymentBsbNumber',
        placeHolder: 'N/A',
      },
    ],
  },
  {
    label: 'Application',
    subFields: [
      {
        label: 'Dependants',
        value: 'Application:dependants',
        placeHolder: 'Application Dependants',
      },
      {
        label: 'Dependants With DOB',
        value: 'Application:dependantsWithDob',
        placeHolder: 'Application Dependants with DOB',
      },
    ],
  },
  {
    label: 'Ownership',
    subFields: OWNERSHIP_MAIL_MERGEFIELDS,
  },
  {
    label: "Owner's Instructions",
    subFields: OWNERS_INSTRUCTIONS_MERGE_FIELDS,
  },
  {
    label: 'Ownership - Primary Owner',
    subFields: PRIMARY_OWNER_MERGE_FIELDS,
  },
  {
    label: 'Authority Fee - Dollar Type',
    subFields: AUTHORITY_FEE_MERGE_FIELDS,
  },
  {
    label: 'Management Authority',
    subFields: AUTHORITY_MERGE_FIELDS,
  },
  {
    label: 'Opportunity_Property',
    subFields: OPPORTUNITY_PROPERTY_MAIL_MERGEFIELDS,
  },
  {
    label: 'Embedded Networks',
    subFields: [
      {
        label: 'Embedded Networks Electricity',
        value: 'Property:embeddedNetworksElectricity',
        placeHolder: 'No',
      },
      {
        label: 'Embedded Networks Electricity Provider',
        value: 'Property:embeddedNetworksElectricityProvider',
        placeHolder: '',
      },
      {
        label: 'Embedded Networks Broadband',
        value: 'Property:embeddedNetworksBroadband',
        placeHolder: 'No',
      },
      {
        label: 'Embedded Networks Broadband Provider',
        value: 'Property:embeddedNetworksBroadbandProvider',
        placeHolder: '',
      },
      {
        label: 'Embedded Networks Gas',
        value: 'Property:embeddedNetworksGas',
        placeHolder: 'No',
      },
      {
        label: 'Embedded Networks Gas Provider',
        value: 'Property:embeddedNetworksGasProvider',
        placeHolder: '',
      },
    ],
  },
];
