/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-lines */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState, useMemo, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { Dispatch } from 'redux';
import { useStyles } from './ShortApplicationStyles';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { ApplicationState } from '../../store/RootReducers';
import SortedFooter from '../../component/sortedFooter/SortedFooter';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import {
  DateRange,
  DependantDateRange,
  GetBuildingsRequest,
  GetPartnerDetailsRequest,
  GetQuestionsRequest,
  PostQuestionsRequest,
  ShortApplicationFormQuestions,
} from '../../models/shortApplication/shortApplication';
import {
  ShortApplicationActions,
  ShortApplicationActionTypes,
} from '../../store/actions/ShortApplicationActions';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import Input from '../../component/input/Input';
import InputSelect from '../../component/inputSelect/InputSelect';
import InputMobile from '../../component/inputMobile/InputMobile';
import InputDate from '../../component/inputDate/InputDate';
import moment from 'moment';
import InputMultiSelect from '../../component/inputMultiSelect/InputMultiSelect';
import { Checkbox, FormControl, FormHelperText } from '@mui/material';
import { theme } from '../../theme/Theme';
import FileUploads from '../../component/fileUpload/FileUploads';
import { FileData } from '../../store/state/FileUploadState';
import Button from '../../component/button/Button';
import { convertFormDataToRequest, isDualOccupancyAllowed } from './ShortApplicationUtils';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import InputArea from '../../component/inputArea/InputArea';
import OtpInput from 'react-otp-input';
import {
  ERROR_MESSAGES,
  generateValidationSchema,
  isDateAllowed,
  LABELS,
  minMonth,
} from './ShortApplicationConstants';
import { TypeHelper } from '../../helper/TypeHelper';
import ToggleButton from '../../component/toggleButton/ToggleButton';
import InputGoogle from '../../component/inputGoogle/InputGoogle';
import { GoogleAddressData } from '../../helper/GooglePlacesHelper';
import { AppSettings } from '../../AppSettings';
import ErrorBanner from '../../component/errorBanner/ErrorBanner';
import { DateFormats } from '../../constants/AppConstants';
import { get } from 'lodash';
import { Question } from '../../component/genericFormRenderer/GenericFormRenderer';

interface ShortApplicationProps
  extends RouteComponentProps,
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}

const ShortApplication: FC<ShortApplicationProps> = ({
  assets,
  sections,
  buildings,
  agencyDetails,
  loadingBuildings,
  loadingQuestions,
  loadingPostQuestions,
  success,
  error,
  getBuildings,
  getQuestions,
  postQuestions,
  resetPostQuestionsError,
  getPartnerDetails,
  resetGetPartnerDetailsError,
  partnerDetails,
  partnerError,
}) => {
  const { search, pathname } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [selectedBranch, setSelectedBranch] = useState<string | null>(params.get('b') || null);
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [otpCountdown, setOtpCountdown] = useState('01:59');
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [otpCount, setOtpCount] = useState(1);
  const [otpCode, setOtpCode] = useState<string>('');
  const [showAdditionalQuestion, setShowAdditionalQuestion] = useState<boolean>(false);
  const classes = useStyles();

  const partner = params.get('p');

  useEffect(() => {
    setCanResendOtp(false);
    const reach = moment().add(2, 'm');
    const timer = setInterval(() => {
      const now = moment();
      const secondsRemain = reach.diff(now, 'seconds');

      const min = Math.floor(secondsRemain / 60);
      const sec = secondsRemain % 60;
      setOtpCountdown(`${TypeHelper.padZeroStart(min)}:${TypeHelper.padZeroStart(sec)}`);

      if (secondsRemain < 1) {
        clearInterval(timer);
        setCanResendOtp(true);
      }
    }, 1000);
  }, [otpCount]);

  useEffect(() => {
    if (error && (error as Error).name === 'PartialContent') {
      setShowOTP(true);
    }
  }, [error]);

  useEffect(() => {
    if (agencyDetails && agencyDetails.code) {
      getBuildings({ agencyCode: agencyDetails.code });
    }
  }, [agencyDetails]);

  useEffect(() => {
    if (buildings && !!buildings.length && selectedBranch) {
      getQuestions({ branchCode: selectedBranch, partner: partner || undefined });
    }
  }, [buildings, selectedBranch]);

  useEffect(() => {
    resetPostQuestionsError();
    resetGetPartnerDetailsError();
    setShowOTP(false);
    window.intercomSettings = {
      ...window.intercomSettings,
      app_id: AppSettings.intercomIds.switchAppId,
    };

    if (partner) getPartnerDetails({ partnerCode: partner });
  }, []);

  useEffect(() => {
    if (success) {
      resetPostQuestionsError();
      window.location.replace('https://www.switchliving.com.au/thank-you-book-a-room/');
    }
  }, [success]);

  const additionalApplicant = {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    gender: '',
    email: '',
    mobile: '',
  };

  // const initialValues2 = useMemo<Record<string, string | undefined | string[]>>(() => {
  //   if (sections === undefined) return {};

  //   sections[0].questions.map((q))
  // }, [sections]);

  const partnerName = partnerDetails?.partnerName;
  const pathNameHasReferral = pathname.split('/').includes('referral');
  const isIDP = partnerName?.includes('IDP');

  const uniPartner = useMemo(
    () =>
      !!(
        partnerName &&
        sections &&
        sections[0]?.questions
          ?.find((q) => q.field === 'universitySelect')
          ?.values?.map((v) => v.toLocaleLowerCase())
          .includes(partnerName.toLocaleLowerCase())
      ),
    [sections, partnerName],
  );

  const initialValues = {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    gender: '',
    email: '',
    mobile: '',
    nationality: '',
    describeYourself: uniPartner ? 'Student' : '',
    universitySelect: uniPartner ? partnerName : '',
    preferredRoomType: '',
    wouldYouConsiderOtherRooms: [],
    haveYoubeenForTour: '',
    preferredMoveIn: '',
    preferredMoveOut: '',
    rentalFrequency: null,
    howWouldYouLikeToPay: '',
    promo: '',
    additionalApplicants: [],
    introduction: '',
    otherRequests: '',
    readyToSign: '',
    confirmation: false,
    photoIdFile: [],
    universityOfferLetterFile: [],
    partner: partnerName,
  };

  const renderQuestion = (
    question: Question,
    values: ShortApplicationFormQuestions,
    setFieldValue: (field: string, value: any) => void,
    setFieldTouched: (field: string, isTouched?: boolean | undefined) => void,
    errors: FormikErrors<ShortApplicationFormQuestions>,
    touched: FormikTouched<ShortApplicationFormQuestions>,
    parentQuestion?: Question,
    index?: number,
  ): ReactNode | null => {
    if (question.field === 'additionalApplicants') {
      const canShowAdditional = isDualOccupancyAllowed(
        values.preferredRoomType,
        values.wouldYouConsiderOtherRooms,
        sections![0].questions.find((q) => q.field === 'preferredRoomType')?.allowDualRoomTypes,
      );
      if (!canShowAdditional) {
        if (values.additionalApplicants!.length !== 0) {
          setFieldValue('additionalApplicants', []);
        }
        return null;
      }
    }

    if (
      ['universitySelect', 'courseEnd'].includes(question.field) &&
      values.describeYourself === 'Co-Living'
    ) {
      return null;
    }

    if (
      question.field === 'otherUniversity' &&
      (values.universitySelect !== 'Other' || values.describeYourself === 'Co-Living')
    ) {
      return null;
    }

    if (question.field === 'universityOfferLetterFile' && values.describeYourself === 'Co-Living') {
      return null;
    }

    const questionField = parentQuestion
      ? `${parentQuestion.field}[${index}].${question.field}`
      : question.field;

    const value = parentQuestion
      ? values[parentQuestion.field] && values[parentQuestion.field][index]
        ? values[parentQuestion.field][index][question.field]
        : ''
      : values[question.field];

    const touchedField = parentQuestion
      ? touched[parentQuestion.field] && touched[parentQuestion.field][index]
        ? touched[parentQuestion.field][index][question.field]
        : undefined
      : touched[question.field];

    const errorField = parentQuestion
      ? errors[parentQuestion.field] && errors[parentQuestion.field][index]
        ? errors[parentQuestion.field][index][question.field]
        : undefined
      : errors[question.field];

    const switchQuestion = () => {
      switch (question.type) {
        case 'text':
          return !isIDP &&
            !pathNameHasReferral &&
            ['consultantName', 'consultantEmail'].includes(question.field) ? null : (
            <Input
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        case 'textArea':
          return (
            <InputArea
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        case 'dropDown': {
          return (
            <InputSelect
              placeholder="Please select"
              key={`${questionField}-${question.display}`}
              value={value}
              values={question.values!.map((v) => ({ display: v, value: v }))}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
              parentInputStyles={classes.dropdown}
              searchable={['universitySelect', 'nationality'].includes(question.field)}
              disabled={
                ['universitySelect', 'describeYourself'].includes(question.field) && uniPartner
              }
              onChange={() => {
                if (question.dependentFields && question.dependentFields.length) {
                  question.dependentFields.map((f: string) => {
                    setFieldValue(f, '');
                  });
                }
              }}
            />
          );
        }
        case 'hiddenDropDown': {
          return !partner &&
            !pathname.split('/').includes('referral') &&
            question.field === 'partner' ? null : (
            <InputSelect
              placeholder="Please select"
              key={`${questionField}-${question.display}`}
              value={partnerName || value}
              values={question.values!.map((v) => ({ display: v, value: v }))}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
              parentInputStyles={classes.dropdown}
              searchable={['universitySelect', 'nationality'].includes(question.field)}
              disabled={!!partnerName}
              onChange={() => {
                if (question.dependentFields && question.dependentFields.length) {
                  question.dependentFields.map((f: string) => {
                    setFieldValue(f, '');
                  });
                }
              }}
            />
          );
        }
        case 'mobile':
          return (
            <InputMobile
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        case 'googleAddress': {
          return (
            <InputGoogle
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: GoogleAddressData) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        }
        case 'date':
          const { allowedDates } = question;
          const minOpenDate = allowedDates
            ? 'field' in allowedDates
              ? minMonth(allowedDates.values[values[allowedDates.field]] || [])
              : minMonth(allowedDates || [])
            : undefined;

          return (
            <InputDate
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value ? moment(value, 'DD/MM/YYYY').toDate() : ''}
              setValue={(newValue: string) =>
                setFieldValue(questionField, moment(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'))
              }
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
              minDate={allowedDates ? undefined : question.showPastDates ? undefined : new Date()}
              maxDate={allowedDates ? undefined : question.showPastDates ? new Date() : undefined}
              disabled={
                (allowedDates as DependantDateRange)?.field &&
                !values[(allowedDates as DependantDateRange)?.field]
              }
              defaultCalendarMonth={minOpenDate && moment(minOpenDate, DateFormats.apiFormat)}
              shouldDisableDate={
                question.allowedDates
                  ? (date) => {
                      if (allowedDates && 'field' in allowedDates) {
                        return !isDateAllowed(
                          date,
                          allowedDates.values[values[allowedDates.field]],
                          question.excludedWeekDays,
                          question.showPastDates,
                        );
                      } else {
                        return !isDateAllowed(
                          date,
                          allowedDates as DateRange[],
                          question.excludedWeekDays,
                          question.showPastDates,
                        );
                      }
                    }
                  : undefined
              }
            />
          );
        case 'nestedQuestions': {
          return (
            <>
              <Text parentStyles={classes.additionalApplicantsHeading}>{question.display}</Text>
              <div className={classes.toggleContainer}>
                <ToggleButton
                  toggleFunction={() => {
                    if (showAdditionalQuestion) {
                      setFieldValue('additionalApplicants', []);
                    } else {
                      setFieldValue('additionalApplicants', [additionalApplicant]);
                    }
                    setShowAdditionalQuestion(!showAdditionalQuestion);
                  }}
                  buttonState={showAdditionalQuestion}
                />
              </div>
              {showAdditionalQuestion && (
                <>
                  <Text parentStyles={classes.additionalApplicantsSubheading}>
                    {LABELS.PLEASE_COMPLETE_ADDITIONAL}
                  </Text>
                  <Box
                    parentStyles={classes.additionalApplicantBox}
                    innerStyles={classes.boxBorder}
                    lightBorder
                  >
                    {question.questions!.map((q) => (
                      <>
                        {renderQuestion(
                          q,
                          values,
                          setFieldValue,
                          setFieldTouched,
                          errors,
                          touched,
                          question,
                          0,
                        )}
                      </>
                    ))}
                  </Box>
                </>
              )}
            </>
          );
        }
        case 'multi-select':
          return (
            <InputMultiSelect
              key={`${questionField}-${question.display}`}
              value={value}
              values={question.values!.map((v) => ({ display: v, value: v }))}
              setValue={(newValue: string[]) => setFieldValue(question.field, newValue)}
              title={question.display}
              mandatory={question.mandatory}
              maxSelection={question.maxSelectionAllowed}
            />
          );
        case 'fileUpload': {
          const value = get(values, questionField);
          return (
            <FormControl className={classes.inputContainerStyle}>
              <div className={classes.fileInputTitleContainer}>
                <Text parentStyles={classes.fileInputTitle}>{question.display}</Text>
                <div className={classes.mandatory}>*</div>
              </div>
              <FileUploads
                multiple
                uploadFile={(data: File[]) => {
                  const oldData = value as File[];
                  const newData = oldData ? oldData.concat(data) : data;
                  setFieldValue(questionField, newData);
                }}
                uploadOnly
                value={value || []}
                id={questionField}
                onDelete={(file: FileData) => {
                  const oldData = value as File[];
                  const newData = oldData.filter((oldFile: File) => oldFile.name !== file.name);
                  setFieldValue(questionField, newData);
                }}
                onSuccess={() => null}
                error={!!errorField && !!touchedField}
              />
              {!!errorField && !!touchedField && (
                <FormHelperText error={!!errorField && !!touchedField}>{errorField}</FormHelperText>
              )}
            </FormControl>
          );
        }
        case 'calculated': {
          const nightsInContract =
            !!values.preferredMoveIn && !!values.preferredMoveOut
              ? moment(values.preferredMoveOut, 'DD/MM/YYYY').diff(
                  moment(values.preferredMoveIn, 'DD/MM/YYYY'),
                  'days',
                )
              : '';
          const weeksInContract =
            !!values.preferredMoveIn && !!values.preferredMoveOut
              ? moment(values.preferredMoveOut, 'DD/MM/YYYY').diff(
                  moment(values.preferredMoveIn, 'DD/MM/YYYY'),
                  'weeks',
                )
              : '';

          return (
            <Input
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={question.field === 'daysStay' ? nightsInContract : weeksInContract}
              setValue={(newValue: string) => setFieldValue(question.field, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              title={question.display}
              mandatory={question.mandatory}
              disabled
              hideEndElement
            />
          );
        }
        case 'HTMLdisclamier':
          return (
            <div className={classes.htmlContainer}>
              <div
                key={`${questionField}-${question.display}`}
                className={classes.htmlText}
                dangerouslySetInnerHTML={{ __html: question.display }}
              />
            </div>
          );
        case 'HTMLConfirmationCheckBox':
          return (
            <div className={classes.htmlContainer}>
              <div
                className={classes.checkboxRow}
                key={`${question.field}-${question.display}`}
                onMouseDown={() => null}
              >
                <Checkbox
                  style={{ color: theme.colors.secondary }}
                  className={classes.checkBoxStyle}
                  checked={!!values[question.field]}
                  onChange={(_, checked) => setFieldValue(question.field, checked)}
                />
                <div
                  className={classes.htmlText}
                  dangerouslySetInnerHTML={{ __html: question.display }}
                />
              </div>
              {!!errorField && !!touchedField && (
                <div className={classes.errorText}>{errorField}</div>
              )}
            </div>
          );
        default:
          return null;
      }
    };
    return (
      <div
        className={classNameGenerator([
          classes.questionExtraSpacing,
          questionField === 'title' && classes.applicantTitle,
          questionField === 'firstName' && classes.firstName,
          questionField === 'middleName' && classes.middleName,
          questionField === 'lastName' && classes.lastName,
        ])}
      >
        {switchQuestion()}
        {!!question.infoText && <div className={classes.questionInfoText}>{question.infoText}</div>}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <div className={classes.root}>
        <AgencyBackground />
        <div className={classes.content}>
          <div className={classes.pageContainer}>
            {partnerError === ERROR_MESSAGES.INVALID_PARTNER_CODE ? (
              <ErrorBanner errorMessage={LABELS.INVALID_PARTNER_CODE} />
            ) : (
              <>
                {!!assets && !loadingQuestions && (
                  <div className={classes.circleLogoRow}>
                    <img
                      src={partnerDetails?.partnerLogo || assets.circularLogo!}
                      className={classes.circleLogo}
                      alt="logo"
                    />
                  </div>
                )}
                <div className={classes.titleContainer}>
                  <div className={classes.title}>
                    {buildings && selectedBranch && !!buildings.length
                      ? `${
                          buildings.find((building) => building.branchCode === selectedBranch)!.name
                        } ${pathNameHasReferral ? 'Agent Referral Form' : 'Application Form'}`
                      : `${pathNameHasReferral ? 'Agent Referral Form' : 'Application Form'}`}
                  </div>
                  {partnerName && <div className={classes.subtitle}>( {partnerName} )</div>}
                </div>
                {loadingBuildings ? (
                  <>
                    <Box loading parentStyles={classes.loadingTitle} />
                    <div className={classes.buildingsGrid}>
                      <Box loading parentStyles={classes.loadingBox} />
                      <Box loading parentStyles={classes.loadingBox} />
                      <Box loading parentStyles={classes.loadingBox} />
                      <Box loading parentStyles={classes.loadingBox} />
                    </div>
                  </>
                ) : (
                  <>
                    {buildings && selectedBranch === null && (
                      <>
                        <Text textVariant="boxTitle">Please select a location</Text>
                        <div className={classes.buildingsGrid}>
                          {buildings.map((building, i) => {
                            const disabled: boolean =
                              // Enabled on general link, referral && IDP temporarily, otherwise disable on general too
                              (partnerName && !isIDP && building.branchCode === 'AU_SWIT_SYD') ||
                              // Uncomment when Syd needs to be disabled again
                              // (!(pathNameHasReferral || isIDP) &&
                              //   building.branchCode === 'AU_SWIT_SYD') ||
                              (building.branchCode === 'AU_SWIT_PER' && pathNameHasReferral);
                            return (
                              <div
                                className={disabled ? classes.disabledBuilding : classes.building}
                                key={i}
                                onClick={() =>
                                  disabled ? null : setSelectedBranch(building.branchCode)
                                }
                              >
                                <div className={classes.buildingName}>{building.name}</div>
                                <div className={classes.buildingAddress}>{building.address}</div>
                                {disabled && building.branchCode === 'AU_SWIT_SYD' && (
                                  <div
                                    className={`${classes.buildingAddress} ${classes.comingSoon}`}
                                  >
                                    Coming soon!
                                  </div>
                                )}
                                {disabled && building.branchCode === 'AU_SWIT_PER' && (
                                  <div
                                    className={`${classes.buildingAddress} ${classes.comingSoon}`}
                                  >
                                    Fully booked for 2024
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                )}
                {loadingQuestions ? (
                  <Box loading parentStyles={classes.loadingForm} />
                ) : (
                  <>
                    {sections && !!sections.length && selectedBranch && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={generateValidationSchema(sections[0].questions)}
                        enableReinitialize
                        validateOnChange
                        onSubmit={async (formData: ShortApplicationFormQuestions) => {
                          const data = await convertFormDataToRequest(
                            formData,
                            selectedBranch,
                            sections[0].questions.map((q) => q.field),
                            otpCode.length === 6 ? otpCode : undefined,
                          );
                          postQuestions(data);
                        }}
                        render={({
                          values,
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched,
                          errors,
                          touched,
                        }) => (
                          <>
                            {console.log('errors', errors)}
                            {sections.map((section) => (
                              <div className={classes.section}>
                                <Text textVariant="boxTitle">{section.heading}</Text>
                                <Box lightBorder innerStyles={classes.boxBorder}>
                                  {section.questions.map((question) => (
                                    <>
                                      {renderQuestion(
                                        question,
                                        values,
                                        setFieldValue,
                                        setFieldTouched,
                                        errors,
                                        touched,
                                      )}
                                    </>
                                  ))}
                                </Box>
                              </div>
                            ))}

                            {showOTP ? (
                              <Box lightBorder>
                                <Text textVariant="boxTitle">{LABELS.OTP_TITLE}</Text>

                                <div className={classes.subtitleRow}>
                                  <Text textVariant="info">
                                    {`${LABELS.OTP_SUBTITLE} `}
                                    <span>
                                      <b>{values.email}</b>
                                    </span>
                                  </Text>
                                </div>
                                <div className={classes.inputRow}>
                                  <OtpInput
                                    onChange={(value: string) => setOtpCode(value)}
                                    numInputs={6}
                                    value={otpCode}
                                    containerStyle={classes.container}
                                    isInputNum
                                    shouldAutoFocus
                                    inputStyle={classes.input}
                                  />
                                </div>
                                {(error === 'Invalid OTP' || error === 'OTP Expired') && (
                                  <div className={classes.errorText}>{error}</div>
                                )}
                                <div className={classes.signupRow}>
                                  <Text textVariant="info">{LABELS.DIDNT_RECIEVE_OTP}</Text>

                                  {canResendOtp ? (
                                    <Button
                                      outline
                                      parentStyles={classes.signupButton}
                                      onPress={() => {
                                        setOtpCount(otpCount + 1);
                                        setOtpCode('');
                                        handleSubmit();
                                      }}
                                    >
                                      {LABELS.RESEND_OTP}
                                    </Button>
                                  ) : (
                                    <div className={classes.timer}>
                                      <Text textVariant="info">{`${LABELS.TRY_AGAIN_IN} ${otpCountdown}`}</Text>
                                    </div>
                                  )}
                                  <Button
                                    loading={loadingPostQuestions}
                                    disabled={
                                      loadingPostQuestions ||
                                      otpCode.length !== 6 ||
                                      error === 'OTP Expired'
                                    }
                                    onPress={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {LABELS.OTP_VERIFY}
                                  </Button>
                                </div>
                              </Box>
                            ) : (
                              <div className={classes.buttonContainer}>
                                <Button
                                  loading={loadingPostQuestions}
                                  disabled={
                                    loadingPostQuestions ||
                                    (!values.email && !values.firstName && !values.confirmation)
                                  }
                                  onPress={() => {
                                    handleSubmit();
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <SortedFooter />
    </div>
  );
};

const loadingBuildings = loadingSelector([
  ShortApplicationActionTypes.GET_SHORT_APPLICATION_BUILDINGS,
]);

const loadingQuestions = loadingSelector([
  ShortApplicationActionTypes.GET_SHORT_APPLICATION_QUESTIONS,
  ShortApplicationActionTypes.GET_PARTNER_DETAILS,
]);

const loadingPostQuestions = loadingSelector([
  ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS,
]);

const error = errorMessageSelector([ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS]);
const partnerError = errorMessageSelector([ShortApplicationActionTypes.GET_PARTNER_DETAILS]);

const mapStateToProps = (state: ApplicationState) => ({
  agencyDetails: state.dashboard.agencyDetails,
  buildings: state.shortApplicationState.buildings,
  sections: state.shortApplicationState.sections,
  success: state.shortApplicationState.success,
  assets: state.dashboard.assets,
  loadingBuildings: loadingBuildings(state),
  loadingQuestions: loadingQuestions(state),
  loadingPostQuestions: loadingPostQuestions(state),
  error: error(state),
  partnerError: partnerError(state),
  partnerDetails: state.shortApplicationState.partner,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBuildings: (data: GetBuildingsRequest) =>
    dispatch(ShortApplicationActions.getShortApplicationBuildingsRequest(data)),
  getQuestions: (data: GetQuestionsRequest) =>
    dispatch(ShortApplicationActions.getShortApplicationQuestionsRequest(data)),
  postQuestions: (data: PostQuestionsRequest) =>
    dispatch(ShortApplicationActions.postShortApplicationQuestionsRequest(data)),
  resetPostQuestionsError: () =>
    dispatch(ShortApplicationActions.postShortApplicationQuestionsReset()),
  getPartnerDetails: (data: GetPartnerDetailsRequest) =>
    dispatch(ShortApplicationActions.getPartnerDetails(data)),
  resetGetPartnerDetailsError: () => dispatch(ShortApplicationActions.getPartnerDetailsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShortApplication));
