import localStorage from './services/LocalStorage';
import { v4 as uuid } from 'uuid';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

/**
 * 1. set placeholder apply-service.uat.sortedservices.com for BaseUrl in build time, set it to uat if it's running locally and process.env.NODE_ENV is 'development'
 * 2. In terraform, ECS task configuration sets APPLY_SERVICE_URL to env variable based on the value from Consul node config
 * 3. replaced the placeholder at docker start time with APPLY_SERVICE_URL in docker-entrypoint.sh
 */

const BaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://apply-service.uat.sortedservices.com'
    : 'https://apply-service.uat.sortedservices.com';

const TenancyBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://tenancy-bff.uat.sortedservices.com/tenancy'
    : 'https://tenancy-bff.uat.sortedservices.com/tenancy';

export const TradeBffBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://trade-service-bff.uat.sortedservices.com/trade'
    : 'https://trade-service-bff.dev.sortedservices.com/trade';

export const bucketName =
  process.env.NODE_ENV === 'production'
    ? 'uat-sorted-apply-service'
    : 'uat-sorted-apply-service';

const SortedWebUrl = BaseUrl.includes('prodau')
  ? 'https://sorted-web.prodau.sortedservices.com/'
  : 'https://sortedweb.uat.sortedservices.com/';

export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
}

const logRocketGroup = BaseUrl.includes('apply-service.prodau.sortedservices.com')
  ? 'sup/apply-prod-i4tz8xx0l2-9apb8z3rgc'
  : 'sup/apply-ui-uat';

const onboardingUIURL = BaseUrl.includes('apply-service.prodau.sortedservices.com')
  ? 'https://onboarding-ui.prodau.sortedservices.com'
  : 'https://onboarding-ui.uat.sortedservices.com';

const switchAppId = BaseUrl.includes('apply-service.prodau.sortedservices.com')
  ? 'jec424x9'
  : 'zu3dq75q';

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl, // DO NOT end with '/'!
    tenancyBaseUrl: TenancyBaseUrl,
    mockServices: false, // test use mocks by using jest.mock(), this is for manual testing
    sortedWebUrl: SortedWebUrl,
  };

  public static readonly logging = {
    logLevel: LogLevel.Trace,
  };

  public static initTesting() {
    AppSettings.logging.logLevel = AppSettings.testSettings.logLevel;

    this.testSettings = {
      ...this.testSettings,
      testing: true,
    };
  }

  public static get currentTestSetting(): TestSettings {
    return AppSettings.testSettings;
  }

  private static testSettings: TestSettings = {
    testing: false,
    logLevel: LogLevel.Debug,
  };

  public static readonly logRocket = {
    group: logRocketGroup,
  };

  public static readonly onboardingUI = {
    url: onboardingUIURL,
  };

  public static intercomIds = {
    switchAppId,
  };

  public static getCookies(name?: string) {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';').reduce((accum, it) => {
      const [name, value] = it.trim().split('=');
      return Object.assign(accum, { [name]: value });
    }, {});

    if (name) {
      return cookies[name];
    } else {
      return cookies;
    }
  }

  public static initApp() {
    const deviceId = this.getCookies('deviceId');
    if (!deviceId) {
      const date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `deviceId=${uuid()};${expires}`;
    }
  }
}
