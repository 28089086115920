import moment from 'moment';
import { capitalize, filter, findIndex } from 'lodash';
import { GetUserResponse } from '../services/remoteSigning/user/User';
import localStorage from '../services/LocalStorage';
import { DateFormats } from '../constants/AppConstants';
import { dollarFormatter } from './CurrencyHelper';
import { DateHelper } from './DateHelper';
import { CalculationHelper } from './CalculationHelper';
import { AgencyDetails } from '../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import {
  Property,
  RentalFrequency,
  UsersInBond,
  GetLeaseResponse,
  ApplicantResponse,
  RentIncreaseLimitType,
  NetworkValue,
} from '../services/remoteSigning/lease/Lease';
import { getNameString } from './NameHelper';
import { replaceDefaultPhone } from './PhoneHelper';
import { PaymentTransactions } from '../services/remoteSigning/payment/Payment';

function getAllTenantsMergeField(tenants: GetUserResponse[], property: Property): string {
  const allValues: string[] = [];
  tenants.forEach((tenant, index) => {
    const values = [
      `Full name of <b>${
        property.state && property.state.toUpperCase() === 'VIC' ? 'renter' : 'tenant'
      } ${index + 1}</b> : ${getNameString(tenant.name, tenant.middleName, tenant.surname)}`,
      `Current address : ${tenant.address ? tenant.address : 'N/A'}`,
      `Phone number : ${replaceDefaultPhone(tenant.phone)}`,
      `Email address : ${tenant.email}`,
    ];
    allValues.push(values.join('</br>').concat('</br>'));
  });
  return allValues.join('</br>');
}
function getAllTenantEmailsMergeField(tenants: GetUserResponse[]): string {
  return tenants
    .map((tenant) => tenant.email)
    .filter((email) => !!email)
    .join(', ');
}

function getAllRentersMergeField(tenants: GetUserResponse[], property: Property): string {
  const allValues: string[] = [];
  tenants.forEach((tenant, index) => {
    const values = [`<b>Renter ${index + 1}</b> Yes ${tenant.email}`];
    allValues.push(values.join('</br>').concat('</br>'));
  });
  return allValues.join('</br>');
}

export const leaseMailMergeFields = (
  gotLease: GetLeaseResponse,
  branch: AgencyDetails,
  agency: AgencyDetails,
) => {
  const {
    property,
    startDate,
    endDate,
    tenants,
    rentalAmount,
    rentalFrequency,
    premisesProvidedWith,
    leaseDuration,
    depositAmount,
    rentScheduledStartDate,
    urgentRepairsAmount,
    oneOffPaymentAmount,
    inspectionDate,
    firstPaymentDate,
    depositPaymentDate,
    primaryTenant,
    bond,
    paymentInfo,
    paymentTransactions,
    childrenPermitted,
    adultsPermitted,
    petPermitted,
    allowedPetsInfo,
    bondReferenceNumber,
    rentalIncrease,
    paymentReferenceId,
    application,
    includeOnBond,
    inclusions,
    conditionReportDate,
    bondDate,
    oneCycleRentalAmount,
    firstRentalIncreaseAmountOneCycle,
    secondRentalIncreaseAmountOneCycle,
  } = gotLease;
  const tenantList: string[] = [];
  const LessorsList: string[] = [];
  const lessorEmailList: string[] = [];
  const lessorPhoneNumberList: string[] = [];
  const lessorFullAddressList: string[] = [];
  const lessorStateandCountryList: string[] = [];
  const secondaryTenantsList: string[] = [];
  const secondaryTenantsEmailList: string[] = [];
  const secondaryTenantsPhoneList: string[] = [];
  const allTenantAddresses: string[] = [];
  const dependants: string[] = [];
  const dependantsWithDob: string[] = [];
  const tenantsIncludedInBond: string[] = [];
  const company =
    property &&
    property.landlords &&
    (property.landlords.find((landlord) => landlord.isCompany) || {}).companyResponse;

  const getAllTenants = (): GetUserResponse[] => {
    const allTenants = [];
    if (primaryTenant) allTenants.push(primaryTenant);
    if (tenants) allTenants.push(...tenants);
    return allTenants;
  };

  const isIncludedOnBond = (includedOnBond: UsersInBond[], user: GetUserResponse): boolean => {
    const userIncludedOnBond = includedOnBond.find(
      (userInBond) => userInBond.userId === user.id && userInBond.onBond,
    );

    return userIncludedOnBond !== undefined;
  };

  const setLandlordsEmail = (u: GetUserResponse) => (u.preferredEmail ? u.preferredEmail : u.email);

  const getRentPayDayString = (
    date: string | null | undefined,
    frequency: RentalFrequency | null | undefined,
  ) => {
    if (date && frequency) {
      const rentPayDate = moment(date, DateFormats.apiFormat);
      const payDay = capitalize(rentPayDate.format('dddd'));
      const payDate = rentPayDate.date();
      switch (frequency) {
        case RentalFrequency.Weekly:
          return `each ${payDay}`;
        case RentalFrequency.Fortnightly:
          return `every second ${payDay}`;
        case RentalFrequency.Monthly:
          return `the ${moment(rentPayDate).format('Do')} of each month`;
        default:
          return '';
      }
    }
    return 'N/A';
  };

  const getEmbeddedNetwork = (property: Property, serviceType: string) => {
    const index = findIndex(property.embeddedNetworksConifg, function (v: NetworkValue) {
      return v.serviceType === serviceType;
    });
    const availability = index !== -1 ? 'Yes' : 'No';
    return availability;
  };

  const getEmbeddedNetworkProvider = (property: Property, serviceType: string) => {
    const index = findIndex(property.embeddedNetworksConifg, function (v: NetworkValue) {
      return v.serviceType === serviceType;
    });
    const provider = index !== -1 ? property.embeddedNetworksConifg![index].provider : '';
    return provider;
  };

  if (primaryTenant) {
    tenantList.push(`${primaryTenant.name} ${primaryTenant.surname}`);
    allTenantAddresses.push(`<br/>${primaryTenant.name} ${primaryTenant.surname}`);
    allTenantAddresses.push(`<br/ >${primaryTenant.address}`);
    if (isIncludedOnBond(includeOnBond!, primaryTenant)) {
      tenantsIncludedInBond.push(`<br/>${primaryTenant.name} ${primaryTenant.surname}`);
    }
  }
  if (tenants) {
    tenants.forEach((u: GetUserResponse) => {
      tenantList.push(`${u.name} ${u.surname}`);
      secondaryTenantsList.push(`${u.name} ${u.surname}`);
      secondaryTenantsEmailList.push(u.email);
      secondaryTenantsPhoneList.push(replaceDefaultPhone(u.phone));
      allTenantAddresses.push(`<br/>${u.name} ${u.surname}`);
      allTenantAddresses.push(`<br/>${u.address}`);
      if (isIncludedOnBond(includeOnBond!, u)) {
        tenantsIncludedInBond.push(`<br/>${u.name} ${u.surname}`);
      }
    });
  }
  if (property && property.landlords) {
    property.landlords.forEach((u: GetUserResponse) => {
      LessorsList.push(`${u.name} ${u.surname}`);
      lessorEmailList.push(setLandlordsEmail(u));
      lessorPhoneNumberList.push(replaceDefaultPhone(u.phone));
      if (u.address) lessorFullAddressList.push(u.address);
      if (u.addressComponents && u.addressComponents.state && u.addressComponents.country)
        lessorStateandCountryList.push(
          `${u.addressComponents.state}, ${u.addressComponents.country}`,
        );
    });
  }
  if (application) {
    application.applicants
      .filter((a: ApplicantResponse) => {
        return a.applicantType === 'DEPENDENT';
      })
      .forEach((a: ApplicantResponse) => {
        dependants.push(`Dependant Name: ${a.firstName} ${a.lastName}`);
        dependantsWithDob.push(
          `Dependant Name: ${a.firstName} ${a.lastName}, Date of Birth: ${a.dob}`,
        );
      });
  }
  return {
    Lessors: {
      'Name': LessorsList.join(', '),
      'Email': lessorEmailList.join(', '),
      'Phone Number': lessorPhoneNumberList.join(', '),
      'Full Address': getLessorAddress(lessorFullAddressList),
      'State and Country': getLessorAddress(lessorStateandCountryList),
      'ABN': company && company.abn ? company.abn : 'N/A',
      'ACN': company && company.acn ? company.acn : 'N/A',
    },
    Agent: {
      'Name':
        property &&
        property.managingAgent &&
        `${property.managingAgent.name} ${property.managingAgent.surname}`,
      'ID': property && property.managingAgent && property.managingAgent.id,
      'Email': property && property.managingAgent && property.managingAgent.email,
      'Phone':
        property && property.managingAgent && replaceDefaultPhone(property.managingAgent.phone),
      'Branch email': property && property.managingAgent && property.managingAgent.email,
    },
    Agency: {
      'Business Name': agency && agency.name,
      'Trading Name': agency && agency.tradingName,
      'Agency License': agency && agency.agentLicenseNumber,
      'Agency ACN': agency && agency.acn,
      'Agency ABN': agency && agency.abn,
    },
    Branch: {
      'Business Name': branch && branch.name,
      'Trading Name': branch && branch.tradingName,
      'Branch License': branch && branch.agentLicenseNumber,
      'Branch ACN': branch && branch.acn,
      'Branch ABN': branch && branch.abn,
      'Branch Address': branch && branch.postalAddress,
      'Branch Phone Number': branch && branch.phone,
      'Branch Email': branch && branch.email,
    },
    Tenants: {
      Name: tenants![0] && tenants![0]!.name,
    },
    Property: {
      'Address': property && property.address,
      'Custom Field': property && property.customAddressField,
      'Bedrooms': property && property.bedrooms,
      'Bathrooms': property && property.bathrooms,
      'Parking': property && property.carspaces,
      'Leasing Agent Name':
        property &&
        property.leasingAgent &&
        `${property.leasingAgent.name} ${property.leasingAgent.surname}`,
      'Leasing Agent Email': property && property.leasingAgent && property.leasingAgent.email,
      'Leasing Agent Phone':
        property && property.leasingAgent && replaceDefaultPhone(property.leasingAgent.phone),
      'Water usage charge': property && property.waterChargesRequired ? 'Yes' : 'No',
    },
    Tenancy: {
      'Unit Number': property && property.unitNumber,
      'Start date': startDate,
      'Start date day': startDate
        ? rentalFrequency && rentalFrequency.toLowerCase() === 'monthly'
          ? moment(startDate, DateFormats.apiFormat).format('Do')
          : moment(startDate, DateFormats.apiFormat).format('dddd')
        : startDate,
      'End date': endDate,
      'Rental frequency': rentalFrequency,
      'Rental frequency Simple': rentalFrequency ? rentalFrequency.slice(0, -2) : rentalFrequency,
      'Duration (Months only)':
        leaseDuration === null
          ? 'The tenancy duration (Months only)'
          : `${DateHelper.formatDurationMonthsOnly(startDate, endDate)}`,
      'Duration (Weeks only)':
        leaseDuration === null
          ? 'The tenancy duration (Weeks only)'
          : `${DateHelper.formatDurationWeeksOnly(leaseDuration)}`,
      'Duration':
        leaseDuration === null
          ? 'The tenancy duration'
          : `${DateHelper.formatDuration(null, gotLease.rentalFrequency, startDate, endDate)}`,
      'Rental amount (weekly)':
        rentalAmount === null ? 'The rental amount' : dollarFormatter(rentalAmount).toString(),
      'Property provided': premisesProvidedWith,
      'Number of adults': adultsPermitted,
      'Number of children': childrenPermitted === 0 ? '0' : childrenPermitted,
      'Pets allowed': petPermitted ? 'Yes' : 'No',
      'Pets description': allowedPetsInfo,
      'Deposit Amount':
        depositAmount === null ? 'Rental first payment' : dollarFormatter(depositAmount).toString(),
      'Rent Schedule StartDate': rentScheduledStartDate,
      'One off payment':
        oneOffPaymentAmount === null
          ? 'One-off payment amount'
          : dollarFormatter(oneOffPaymentAmount).toString(),
      'Property viewed on': inspectionDate || 'N/A',
      'Maximum urgent repair amount':
        urgentRepairsAmount === null
          ? 'Maximum urgent repair amount'
          : dollarFormatter(urgentRepairsAmount).toString(),
      'State Bond Office': property!.stateBondOffice,
      'Deposit Payment Date': depositPaymentDate,
      'First Payment Date': firstPaymentDate,
      'Tenants': tenantList.toString(),
      'Primary Tenant Salutation': (primaryTenant && primaryTenant.salutation) || 'Salutation',
      'Primary Tenant': primaryTenant
        ? `${primaryTenant.name} ${primaryTenant.surname}`
        : 'Primary tenant',
      'Primary Tenant Email': primaryTenant ? primaryTenant.email : "Primary tenant's email",
      'Primary Tenant Phone Number': primaryTenant
        ? replaceDefaultPhone(primaryTenant.phone)
        : "Primary tenant's phone number",
      'Secondary Tenants': secondaryTenantsList.join(', '),
      'Secondary Tenants Email': secondaryTenantsEmailList.join(', '),
      'Secondary Tenants Phone Number': secondaryTenantsPhoneList.join(', '),
      'Tenants with addresses': allTenantAddresses.join(' '),
      'Current Date': moment().format(DateFormats.apiFormat),
      'One Cycle Rental Amount':
        oneCycleRentalAmount === null
          ? 'One Cycle Rental Amount'
          : dollarFormatter(oneCycleRentalAmount).toString(),
      'Bond Amount': bond === null ? 'Bond amount' : dollarFormatter(bond).toString(),
      'Payment method':
        paymentInfo.payment_provider && paymentInfo.payment_provider.toUpperCase() === 'SORTED'
          ? 'via Sorted'
          : 'via Direct Payment',
      'Payment method type':
        paymentInfo.payment_provider && paymentInfo.payment_provider.toUpperCase() === 'SORTED'
          ? 'Sorted'
          : 'Direct Payment',
      'Payment provider': paymentInfo.payment_provider,
      'Biller Info': paymentInfo.biller_info,
      'Payment Reference': paymentReferenceId,
      'Bond Reference Number': bondReferenceNumber,
      'First Weekly Rent Adjustment Amount':
        rentalIncrease && rentalIncrease.firstAmount
          ? dollarFormatter(rentalIncrease.firstAmount).toString()
          : 'N/A',
      'First One Cycle Rent Adjustment Amount': firstRentalIncreaseAmountOneCycle
        ? dollarFormatter(firstRentalIncreaseAmountOneCycle).toString()
        : 'N/A',
      'First Rent Adjustment Date':
        rentalIncrease && rentalIncrease.firstDate ? rentalIncrease.firstDate : 'N/A',
      'Second Weekly Rent Adjustment Amount':
        rentalIncrease && rentalIncrease.secondAmount
          ? dollarFormatter(rentalIncrease.secondAmount).toString()
          : 'N/A',
      'Second One Cycle Rent Adjustment Amount': secondRentalIncreaseAmountOneCycle
        ? dollarFormatter(secondRentalIncreaseAmountOneCycle).toString()
        : 'N/A',
      'Second Rent Adjustment Date':
        rentalIncrease && rentalIncrease.secondDate ? rentalIncrease.secondDate : 'N/A',
      'Tenants Included In Bond': tenantsIncludedInBond.join('</br>'),
      'Inclusions': inclusions,
      'Condition report date': conditionReportDate,
      'Bond Payment Date': bondDate || 'N/A',
      'Rent payment day': getRentPayDayString(
        rentScheduledStartDate,
        rentalFrequency as RentalFrequency,
      ),
      'Rent Increase Limit Type': getRentIncreaseLimitTypeLabels(
        rentalIncrease
          ? (rentalIncrease.rentIncreaseLimitType as RentIncreaseLimitType)
          : undefined,
      ),
      'All tenant details': getAllTenantsMergeField(getAllTenants(), property!!),
      'All tenant emails': getAllTenantEmailsMergeField(getAllTenants()),
      'All renter emails': getAllRentersMergeField(getAllTenants(), property!!),
      'Account name': paymentInfo.accountName,
      'Account number': paymentInfo.accountNumber,
      'BSB': paymentInfo.bsbNumber,
      'Recipient Name': paymentInfo.recipientName,
      'Recipient Address': paymentInfo.recipientAddress,
      'Bank Name': paymentInfo.bankName,
      'Bank Address': paymentInfo.bankAddress,
      'Swift Bic': paymentInfo.swiftBic,
      'Rent First Due Date': getTransactionDate(paymentTransactions, 'RentFirst'),
      'Rent Bond Due Date': getTransactionDate(paymentTransactions, 'RentBond'),
      'Rent Deposit Due Date': getTransactionDate(paymentTransactions, 'RentDeposit'),
    },
    Application: {
      'Dependants': dependants.join('</br>'),
      'Dependants With DOB': dependantsWithDob.join('</br>'),
    },
    'Embedded Networks': {
      'Embedded Networks Electricity': getEmbeddedNetwork(property!, 'Electricity'),
      'Embedded Networks Electricity Provider': getEmbeddedNetworkProvider(
        property!,
        'Electricity',
      ),
      'Embedded Networks Broadband': getEmbeddedNetwork(property!, 'Broadband'),
      'Embedded Networks Broadband Provider': getEmbeddedNetworkProvider(property!, 'Broadband'),
      'Embedded Networks Gas': getEmbeddedNetwork(property!, 'Gas'),
      'Embedded Networks Gas Provider': getEmbeddedNetworkProvider(property!, 'Gas'),
    },
  };
};

const getTransactionDate = (data: PaymentTransactions[], type: string) => {
  let transactions = data.filter((transaction) => transaction.paymentType === type);
  return transactions.length > 0 ? transactions[0].paymentDate : '';
};

const getLessorAddress = (data: string[]) =>
  data.length
    ? data.every((val, i, arr) => val === arr[0])
      ? data[0]
      : data.join(' and ')
    : 'N/A';

export const getRentIncreaseLimitTypeLabels = (limitType?: RentIncreaseLimitType): string => {
  switch (limitType) {
    case RentIncreaseLimitType.DOLLAR:
      return 'Fixed Dollar Increase ($)';
    case RentIncreaseLimitType.PERCENTAGE:
      return 'Fixed Percentage Increase (%)';
    case RentIncreaseLimitType.CPI:
      return 'Consumer Price Index (CPI)';
    case RentIncreaseLimitType.MRR:
      return 'Market Rent Review';
    default:
      return 'N/A';
  }
};

export const mailMergeFieldMapper = (gotLease: GetLeaseResponse) => {
  const agency = JSON.parse(localStorage.getItem('agency')!);
  const branchCode = parseInt(localStorage.getItem('branchCode')!, 10);
  const branches = JSON.parse(localStorage.getItem('branches')!);
  const branch = branches
    ? branches.filter((element: AgencyDetails) => element.id === branchCode)[0]
    : [];
  return leaseMailMergeFields(gotLease, branch, agency);
};
